/* Submit form when Enter given on a Select input element (search filter form) */
$(document).on('keypress', 'form select', function(event) {
  if (event.which==13) {
    event.target.form.requestSubmit();
  }
})

/* Submit form directly on changing one of the input fields */
$(document).on('change', "form [data-auto-submit='true'], form[data-auto-submit='true']", function(event) {
  // Submit this form using TurboStreams
  event.target.form.requestSubmit();
})
