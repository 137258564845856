//---- Bootstrap ----
// Load Bootstrap after jQuery so they can merge (See Bootstrap Javascript documentation)
import * as bootstrap from "bootstrap"


/* Ivm de horizontale dropdownmenus moeten we een probleem oplossen waarbij een tijdelijke dropdown over een bestaande dropdown heenvalt. Verwijder tijdelijk de actieve dropdown */
document.addEventListener("shown.bs.dropdown", function(event) {
  if (event.target.classList.contains('nav-link')) {
    let active_elm= document.querySelector('#main_menu-toggler li.nav-item.dropdown.active');
    if (active_elm && event.target.parentElement!=active_elm) {
      active_elm.classList.remove('active');
      active_elm.classList.add('was-active');
      let active_btn= active_elm.querySelector('a[aria-expanded="true"]');
      if (active_btn) {
        active_btn.ariaExpanded="false"
      }

    }
  }
});


document.addEventListener("hidden.bs.dropdown", function(event) {
  if (event.target.classList.contains('nav-link')) {
    let active_elm= document.querySelector('#main_menu-toggler li.nav-item.dropdown.was-active');
    if (active_elm && event.target.parentElement!=active_elm) {
      active_elm.classList.remove('was-active');
      active_elm.classList.add('active');
      let active_btn= active_elm.querySelector('a[aria-expanded="false"]');
      if (active_btn) {
        active_btn.ariaExpanded="true"
      }

    }
  }
});
